import { useLocation } from '@reach/router';

import getParamByName from 'utilities/getParamByName';

const useParam = (query) => {
  const { search } = useLocation();
  return getParamByName(query, search);
};

export default useParam;
