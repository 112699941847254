import { Link } from 'gatsby';
import React from 'react';

import Container from 'components/Container/Container';
import Layout from 'components/Layout';
import Title from 'components/Title/Title';

import { useAuthUser } from 'utilities/AuthUser';

import {
  banner,
  navContainer,
  nav,
  active,
  link,
  btn,
} from './ProfileContainer.module.scss';
import { linkUnderlined } from 'styles/components/links.module.scss';

const ProfileContainer = ({ children, title }) => {
  const { logout } = useAuthUser();

  return (
    <Layout fullWidth={true} hasCustomSection={false}>
      <div className={banner}>
        <Title isBanner={true}>{title}</Title>
      </div>
      <div className={navContainer}>
        <Container withPadding={true}>
          <nav className={nav}>
            <Link
              activeClassName={active}
              className={`${linkUnderlined} ${link}`}
              to="/app/profile"
            >
              Λογαριασμός
            </Link>
            <Link
              activeClassName={active}
              className={`${linkUnderlined} ${link}`}
              to="/app/address"
            >
              Διευθύνσεις
            </Link>
            <Link
              activeClassName={active}
              className={`${linkUnderlined} ${link}`}
              to="/app/business"
            >
              Για επιχειρήσεις
            </Link>
            <Link
              activeClassName={active}
              className={`${linkUnderlined} ${link}`}
              to="/app/history"
            >
              Ιστορικό Παραγγελιών
            </Link>
            <button className={`${linkUnderlined} ${btn}`} onClick={logout}>
              Αποσύνδεση
            </button>
          </nav>
        </Container>
      </div>
      <Container withPadding={true} style={{ paddingBottom: '4rem' }}>
        {children}
      </Container>
    </Layout>
  );
};

export default ProfileContainer;
