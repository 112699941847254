import React, { useState, useEffect } from 'react';
import { IconContext } from 'react-icons';
import { FiTrash2 } from 'react-icons/fi';

import {
  outerContainer,
  contentContainer,
  container,
  addressesContainer,
  formContainer,
  successMsg,
  active,
  btn,
  addBtn,
  editBtn,
  deleteBtn,
  addressLinePrimary,
  addressLineSecondary,
} from 'components/Address/Address.module.scss';
import BusinessForm from 'components/Business/BusinessForm/BusinessForm';
import LoadingPrimaryButton from 'components/Buttons/LoadingPrimaryButton';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import { useAuthUser } from 'utilities/AuthUser';

import api from 'api/app';

const Business = () => {
  const { user, updateUser: update } = useAuthUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [address, setAddress] = useState(
    (user && user.companyAddresses && user.companyAddresses[0]) || {}
  );

  useEffect(() => {
    let mounted = true;
    if (success === true) {
      setTimeout(() => {
        mounted && setSuccess(false);
      }, 3000);
    }
    return () => {
      mounted = false;
    };
  }, [success]);

  const addAddress = () => {
    setAddress({});
  };

  const editAddress = (address) => {
    setAddress(address);
  };

  const removeAddress = async (index) => {
    try {
      setLoading(true);
      await api.put('/users/details', {
        ...user,
        companyAddresses: [
          ...user.companyAddresses.slice(0, index),
          ...user.companyAddresses.slice(index + 1),
        ],
      });
      update();
      setSuccess(true);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (data) => {
    let companyAddresses = user.companyAddresses
      ? [...user.companyAddresses]
      : [];
    try {
      setLoading(true);
      await api.put('/users/details', {
        ...user,
        companyAddresses: [...companyAddresses, { ...data }],
      });
      update();
      setSuccess(true);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className={outerContainer}>
      <h4>Στοιχεία επιχειρήσεων</h4>
      <div className={contentContainer}>
        <ul className={addressesContainer}>
          {user &&
            user.companyAddresses &&
            user.companyAddresses.map((address, index) => (
              <li
                key={index}
                role="presentation"
                onClick={() => editAddress(address)}
                onKeyDown={() => editAddress(address)}
                className={editBtn}
              >
                <p className={addressLinePrimary}>
                  <span>{`${address.companyName}, ${address.profession}`}</span>
                  <button
                    type="button"
                    className={deleteBtn}
                    onClick={(ev) => {
                      ev.preventDefault();
                      removeAddress(index);
                    }}
                  >
                    <IconContext.Provider
                      value={{ color: '#b1b1af', size: '1.125rem' }}
                    >
                      <FiTrash2 />
                    </IconContext.Provider>
                  </button>
                </p>
                <p
                  className={addressLineSecondary}
                >{`${address.companyStreet} ${address.companyStreetNumber}, ${address.companyCity}
                    ${address.companyPostalCode}`}</p>
              </li>
            ))}
          <li>
            <button type="button" onClick={addAddress} className={addBtn}>
              + Προσθέστε επιχείρηση
            </button>
          </li>
        </ul>
        <BusinessForm
          containerClass={formContainer}
          address={address}
          onSubmit={updateUser}
        />
      </div>
      <>
        <div className={container}>
          <LoadingPrimaryButton
            type="submit"
            loading={loading}
            id="update-user"
            form="address-form"
            buttonClass={btn}
          >
            ΕΝΗΜΕΡΩΣΗ ΣΤΟΙΧΕΙΩΝ
          </LoadingPrimaryButton>
          {error && (
            <ErrorMessage>
              Η ενημέρωση των στοιχείων δεν ήταν επιτυχής.
            </ErrorMessage>
          )}
        </div>
        <p className={`${successMsg}${success ? ' ' + active : ''}`}>
          Τα στοιχεία χρήστη ενημερώθηκαν επιτυχώς!
        </p>
      </>
    </div>
  );
};

export default Business;
