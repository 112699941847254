// extracted by mini-css-extract-plugin
export var outerContainer = "Address-module--outerContainer--2WS3S";
export var contentContainer = "Address-module--contentContainer--386ho";
export var container = "Address-module--container--d_-rG";
export var formContainer = "Address-module--formContainer--1hNTV";
export var addressesContainer = "Address-module--addressesContainer--26e93";
export var successMsg = "Address-module--success-msg--1kdmr";
export var active = "Address-module--active--2ucjW";
export var addressLinePrimary = "Address-module--addressLinePrimary--3xeSq";
export var addressLineSecondary = "Address-module--addressLineSecondary--2bylu";
export var btn = "Address-module--btn--1Z3_M";
export var deleteBtn = "Address-module--deleteBtn--WHs-m";
export var addBtn = "Address-module--add-btn--3B49I";
export var editBtn = "Address-module--edit-btn--3AexC";