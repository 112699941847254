import { navigate } from 'gatsby';
import React from 'react';

import { useAuthUser } from './AuthUser';

const UnauthorizedRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn, loading } = useAuthUser();

  if (isLoggedIn && !loading) {
    if (typeof window !== 'undefined') {
      navigate('/');
      return null;
    }
  }
  return <Component {...rest} />;
};

export default UnauthorizedRoute;
