export const formatDate = (
  date,
  {
    day = true,
    month = true,
    year = true,
    fullYear = true,
    separator = '/',
  } = {}
) => {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = fullYear
    ? date.getFullYear()
    : date.getFullYear().toString().substring(0, 2);
  const parts = [];
  day && parts.push(dd);
  month && parts.push(mm);
  year && parts.push(yyyy);
  return parts.join(separator);
};
