import { Router } from '@reach/router';
import React from 'react';

import Account from 'components/Account/Account';
import ForgotPassword from 'components/Account/ForgotPassword';
import ResetPassword from 'components/Account/ResetPassword';
import Address from 'components/Address/Address';
import Business from 'components/Business/Business';
import History from 'components/History/History';
import Profile from 'components/Profile/Profile';
import ProfileContainer from 'components/ProfileContainer/ProfileContainer';

import AuthorizedRoute from 'utilities/AuthorizedRoute';
import UnauthorizedRoute from 'utilities/UnauthorizedRoute';

const App = () => (
  <Router basepath="/app">
    <AuthorizedRoute
      path="/profile"
      component={() => (
        <ProfileContainer title="Λογαριασμός χρήστη">
          <Profile />
        </ProfileContainer>
      )}
    />
    <AuthorizedRoute
      path="/address"
      component={() => (
        <ProfileContainer title="Διευθύνσεις">
          <Address />
        </ProfileContainer>
      )}
    />
    <AuthorizedRoute
      path="/business"
      component={() => (
        <ProfileContainer title="Για επιχειρήσεις">
          <Business />
        </ProfileContainer>
      )}
    />
    <AuthorizedRoute
      path="/history"
      component={() => (
        <ProfileContainer title="Ιστορικό Παραγγελιών">
          <History />
        </ProfileContainer>
      )}
    />
    <UnauthorizedRoute path="/account" component={Account} />
    <UnauthorizedRoute path="/forgot-password" component={ForgotPassword} />
    <UnauthorizedRoute path="/reset-password" component={ResetPassword} />
  </Router>
);

export default App;
