import React, { useState, useEffect } from 'react';

import LoadingPrimaryButton from 'components/Buttons/LoadingPrimaryButton';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ProfileForm from 'components/Profile/ProfileForm/ProfileForm';

import { useAuthUser } from 'utilities/AuthUser';

import api from 'api/app';

import { container, successMsg, active, btn } from './Profile.module.scss';

const Profile = () => {
  const { user, updateUser: update } = useAuthUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (success === true) {
      setTimeout(() => {
        mounted && setSuccess(false);
      }, 3000);
    }
    return () => {
      mounted = false;
    };
  }, [success]);

  const updateUser = async (data) => {
    try {
      setLoading(true);
      await api.put('/users/details', { ...data });
      update();
      setSuccess(true);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ProfileForm
      user={user}
      onSubmit={updateUser}
      actions={
        <>
          <div className={container}>
            <LoadingPrimaryButton
              type="submit"
              loading={loading}
              id="update-user"
              buttonClass={btn}
            >
              ΕΝΗΜΕΡΩΣΗ ΣΤΟΙΧΕΙΩΝ
            </LoadingPrimaryButton>
            {error && (
              <ErrorMessage>
                Η ενημέρωση των στοιχείων δεν ήταν επιτυχής.
              </ErrorMessage>
            )}
          </div>
          <p className={`${successMsg}${success ? ' ' + active : ''}`}>
            Τα στοιχεία χρήστη ενημερώθηκαν επιτυχώς!
          </p>
        </>
      }
    />
  );
};

export default Profile;
