import React from 'react';

import formatMoney from 'utilities/formatMoney';

import * as styles from './OrderInfo.module.scss';

const OrderInfo = ({ order }) => {
  return (
    <div className={styles.container}>
      <div className={styles.addressContainer}>
        <h4 className={styles.title}>Διεύθυνση παράδοσης</h4>
        <p>{`${order.street} ${order.streetNumber}, ${order.city} ${order.postalCode}`}</p>
        <p>{`${order.nameAtBell}, Όροφος: ${order.floor}`}</p>
        <h4 className={styles.title} style={{ marginTop: '2rem' }}>
          Τιμολόγιο
        </h4>
        <p>{order.invoice === true ? 'Ναι' : 'Όχι'}</p>
      </div>
      <div className={styles.sumContainer}>
        <p className={styles.sumLine}>
          <span className={styles.pricingText}>Αξία προϊόντων</span>
          <span className={styles.pricingAmount}>
            {formatMoney(order.itemsPrice)} €
          </span>
        </p>
        {!!order.cashShippingFee && (
          <p className={styles.sumLine}>
            <span className={styles.pricingText}>Κόστος Αντικαταβολής</span>
            <span className={styles.pricingAmount}>
              +{formatMoney(order.cashShippingFee)} €
            </span>
          </p>
        )}
        <p className={styles.sumLine}>
          <span className={styles.pricingText}>Μεταφορικά</span>
          <span className={styles.pricingAmount}>
            +{formatMoney(order.shippingFee)} €
          </span>
        </p>
        <hr />
        <p className={`${styles.sumLine} ${styles.bold}`}>
          <span className={styles.pricingText}>ΣΥΝΟΛΟ</span>
          <span className={styles.pricingAmount}>
            {formatMoney(order.totalPrice)} €
          </span>
        </p>
      </div>
    </div>
  );
};

export default OrderInfo;
