import React from 'react';

import * as styles from './ProductList.module.scss';

const ProductList = ({ products }) => {
  return (
    <ul className={styles.container}>
      <>
        {products.map((item) => (
          <li key={item.id || item._id} className={styles.item}>
            <figure className={styles.figure}>
              <img
                className={styles.img}
                src={`${process.env.GATSBY_API_URL}/${(
                  item.thumbnail || item.image
                ).replace(/^\//, '')}`}
                alt={item.title}
              />
            </figure>
            <div className={styles.content}>
              <h4 className={styles.title}>
                <span>{item.title}</span>
                <span>
                  {((item.quantity * item.finalPrice) / 100).toFixed(2)} €
                </span>
              </h4>
              <p className={styles.price}>
                ΠΟΣΟΤΗΤΑ {item.quantity} x {(item.finalPrice / 100).toFixed(2)}{' '}
                €
              </p>
            </div>
          </li>
        ))}
      </>
    </ul>
  );
};

export default ProductList;
