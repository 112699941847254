// extracted by mini-css-extract-plugin
export var noHistory = "History-module--noHistory--1XlQ1";
export var container = "History-module--container--2BsUq";
export var header = "History-module--header--V7UQW";
export var content = "History-module--content--30rT8";
export var orderContainer = "History-module--orderContainer--2NAND";
export var collapse = "History-module--collapse--3W1zA";
export var colS = "History-module--colS--2QxF0";
export var colM = "History-module--colM--2WM59";
export var price = "History-module--price--1YXN4";
export var pending = "History-module--pending--7cr4p";
export var accepted = "History-module--accepted--3nRnX";
export var declined = "History-module--declined--gPADX";
export var completed = "History-module--completed--3pKPV";
export var detailsContainer = "History-module--detailsContainer--1UkM0";
export var isOpen = "History-module--isOpen--1oSZ8";
export var icon = "History-module--icon--TqG-o";