const validateTaxId = (taxId) => {
  if (!taxId || !taxId.match(/^\d{9}$/) || taxId === '000000000') {
    return false;
  }

  let m = 1;
  let sum = 0;
  for (let i = 7; i >= 0; i--) {
    m *= 2;
    sum += taxId.charAt(i) * m;
  }

  return (sum % 11) % 10 === parseInt(taxId.charAt(8), 10);
};

export default validateTaxId;
