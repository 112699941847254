import React from 'react';
import { useForm } from 'react-hook-form';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import {
  container,
  inputContainer,
  label,
} from 'components/Login/Login.module.scss';
import { input } from 'components/Register/Register.module.scss';

const Register = ({ onSubmit, actions }) => {
  const { register, handleSubmit, getValues, errors } = useForm();

  const repeatVal = (passwordRepeat) =>
    passwordRepeat === getValues().password || 'Ο κωδικός δεν είναι ο ίδιος';

  return (
    <form
      className={`${container}`}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={inputContainer}>
        <label className={label} htmlFor="email">
          Email
        </label>
        <input
          name="email"
          type="email"
          aria-label="Email"
          className={input}
          ref={register({
            required: 'Το email είναι υποχρεωτικό',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Λάθος μορφή email',
            },
          })}
        />
        {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
      </div>
      <div className={inputContainer}>
        <label className={label} htmlFor="email">
          Κωδικός πρόσβασης
        </label>
        <input
          name="password"
          type="password"
          aria-label="Κωδικός πρόσβασης"
          className={input}
          ref={register({
            required: 'Το πεδίο Κωδικός είναι υποχρεωτικό',
            minLength: { value: 3, message: 'Πολύ μικρός κωδικός' },
            maxLength: { value: 100, message: 'Πολύ μεγάλος κωδικός' },
          })}
        />
        {errors.password && (
          <ErrorMessage>{errors.password.message}</ErrorMessage>
        )}
      </div>
      <div className={inputContainer}>
        <label className={label} htmlFor="email">
          Επαλήθευση κωδικού πρόσβασης
        </label>
        <input
          name="passwordRepeat"
          type="password"
          aria-label="Επαλήθευση κωδικού πρόσβασης"
          className={input}
          ref={register({
            required: 'Το πεδίο Επαλήθευση Κωδικού είναι υποχρεωτικό',
            validate: repeatVal,
          })}
        />
        {errors.passwordRepeat && (
          <ErrorMessage>{errors.passwordRepeat.message}</ErrorMessage>
        )}
      </div>
      {actions}
    </form>
  );
};

export default Register;
