import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import OrderInfo from 'components/History/OrderInfo/OrderInfo';
import ProductList from 'components/History/ProductList';

import useData from 'data/useData';

import { useAuthUser } from 'utilities/AuthUser';
import { formatDate } from 'utilities/dateUtils';
import formatMoney from 'utilities/formatMoney';

import * as styles from './History.module.scss';

const getStatusText = (status) => {
  switch (status) {
    case 'declined':
      return 'Απορρίφθηκε';
    case 'completed':
      return 'Ολοκληρώθηκε';
    default:
      return 'Σε εκκρεμότητα';
  }
};

const History = () => {
  const { isLoggedIn } = useAuthUser();
  const [selected, setSelected] = useState(null);
  const { data: history } = useData(isLoggedIn ? `/orders/user/history` : null);

  if (!history || (history && Array.isArray(history) && history.length < 1)) {
    return (
      <p className={styles.noHistory}>
        Δεν υπάρχει διαθέσιμο ιστορικό παραγγελιών.
      </p>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.colM}>Αρ. παραγγελίας</div>
        <div className={styles.colM}>Ημερομηνία</div>
        <div className={styles.colM}>Αποστολή σε</div>
        <div className={styles.colM}>Κατάσταση</div>
        <div className={styles.colM}>Σύνολο</div>
        <div className={styles.colS}></div>
      </div>
      <div className={styles.content}>
        {history.map((item, index) => (
          <div key={item.id} className={styles.orderContainer}>
            <button
              onClick={() => {
                setSelected(selected === index ? null : index);
              }}
              className={styles.collapse}
            >
              <div className={styles.colM}>{item.friendlyId}</div>
              <div className={styles.colM}>
                {formatDate(new Date(item.createdAt))}
              </div>
              <div
                className={styles.colM}
              >{`${item.firstname} ${item.lastname}`}</div>
              <div
                className={`${styles.colM} ${styles[item.shopResponseStatus]}`}
              >
                {getStatusText(item.shopResponseStatus)}
              </div>
              <div className={`${styles.colM} ${styles.price}`}>
                {formatMoney(item.totalPrice) + ' €'}
              </div>
              <div
                className={`${styles.colS} ${styles.icon} ${
                  selected === index && styles.isOpen
                }`}
              >
                <FaChevronDown />
              </div>
            </button>
            <div
              className={`${styles.detailsContainer} ${
                selected === index && styles.isOpen
              }`}
            >
              <ProductList products={item.products} />
              <OrderInfo order={item} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default History;
