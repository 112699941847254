import React from 'react';

import { container } from './AuthWidget.module.scss';

const AuthWidget = ({
  title = null,
  form,
  children,
  containerClass = false,
}) => {
  return (
    <section
      className={`${container}${containerClass ? ' ' + containerClass : ''}`}
    >
      {title}
      {form}
      {children}
    </section>
  );
};

export default AuthWidget;
